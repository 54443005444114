import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Procedures = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Procedures");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const categoryOptions = [
    { label: "Adjunctive Services", value: "Adjunctive Services", color: "primary" },
    { label: "Diagnostic", value: "Diagnostic", color: "primary" },
    { label: "Endodontics", value: "Endodontics", color: "primary" },
    { label: "Implant Services", value: "Implant Services", color: "primary" },
    { label: "Oral and Maxillofacial Surgery", value: "Oral and Maxillofacial Surgery", color: "primary" },
    { label: "Orthodontics", value: "Orthodontics", color: "primary" },
    { label: "Periodontics", value: "Periodontics", color: "primary" },
    { label: "Preventive", value: "Preventive", color: "primary" },
    { label: "Prosthodontics Fixed", value: "Prosthodontics Fixed", color: "primary" },
    { label: "Prosthodontics Removable", value: "Prosthodontics Removable", color: "primary" },
    { label: "Restorative", value: "Restorative", color: "primary" },
  ];

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "code",
        type: "text",
        placeholder: "Code",
        label: "Code",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Code",
        derivedValue: "code=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "code",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "code",
        showOrHideFields: [],
        fieldType: 'Link',
        fieldName: "code",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "hospitalId",
        type: "relateAutoComplete",
        placeholder: "Practice ID",
        label: "Practice ID",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Practice ID",
        derivedValue: "hospitalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1009,
        searchField: "hospitalID",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["hospitalId"],
        controllerName: "Hospitals",
        searchApi: "hospitals",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "hospitalId",
        showOrHideFields: [],
        fieldName: "hospitalId",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "Hospitals_hospitalId",
      //   type: "text",
      //   placeholder: "Hospitals_hospitalId",
      //   label: "Hospitals_hospitalId",
      //   header: "Hospitals hospitalId",
      //   width: 110,
      //   parentField: "hospitalId",
      //   id: "Hospitals_hospitalId",
      //   displayinlist: "true",
      //   fieldType: "RACSubField",
      //   textAlign: "Center",
      //   subFieldName: "hospitalId",
      //   show: true,
      //   field: "Hospitals_hospitalId",
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },

      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        style: {
          maxWidth: '500px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
          textOverflow: 'ellipsis',
        },
      },
      {
        name: "displayFee",
        type: "number",
        placeholder: "Fee",
        label: "Fee",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Fee",
        derivedValue: "fee=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "fee",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "displayFee",
        showOrHideFields: [],
        fieldName: "abbreviation",
        fieldType: "number",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "category",
        type: "dropDown",
        placeholder: "Category",
        label: "Category",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Category",
        derivedValue: "category=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "category",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: categoryOptions,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "category",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "defaultProcedure",
        type: "radio",
        placeholder: "Default Procedure",
        label: "Default Procedure",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Default Procedure",
        derivedValue: "defaultProcedure=undefined",
        capitalizeTableText: true,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "defaultProcedure",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        textAlign: "center",
        show: true,
        style: {},
        fieldType: 'dropDown',
        options: [
          { label: 'True', value: true, color: 'success' },
          { label: 'False', value: false, color: 'danger' },
          { label: 'False', value: undefined, color: 'danger' }],
        field: "defaultProcedure",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "doNotUsuallyBillToInsurance",
        type: "checkbox",
        placeholder: "Do not usually bill to insurance",
        label: "Do not usually bill to insurance",
        width: "160px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Do not usually bill to insurance",
        derivedValue: "doNotUsuallyBillToInsurance=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "doNotUsuallyBillToInsurance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        options: [{ label: 'False', value: false, color: 'danger' }, { label: 'False', value: undefined, color: 'danger' }],
        textAlign: "Center",
        show: true,
        field: "doNotUsuallyBillToInsurance",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    data.push({
      "show": true,
      "textAlign": "center",
      "width": 60,
      "fieldType": "Actions",
      "field": "Actions",
      "header": "Actions",
      "filter": false,
      "sortable": false,
      includeDependent: false,
    })
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [{
      name: "hospitalId",
      type: "relateAutoComplete",
      placeholder: "Practice ID",
      label: "Practice ID",
      width: "150px",
      addFormOrder: 1,
      editFormOrder: 1,
      derivedValue: "hospitalId=undefined",
      capitalizeTableText: false,
      sortable: false,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "hospitalId",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "true",
      controllerId: 1009,
      searchField: "fullHospitalName",
      fieldType: "relateAutoComplete",
     
      controllerName: "hospitals",
      searchApi: apiCalls.hospitals,
      isMultiple: false,
      textAlign: "Center",
      show: true,
      field: "hospitalId",
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      isAddFormHidden: false,
      isEditFormHidden: false,
    },
    {
      name: "code",
      type: "uniqueField",
      placeholder: "Code",
      label: "Code",
      width: "130px",
      addFormOrder: 2,
      editFormOrder: 2,
      derivedValue: "code=undefined",
      capitalizeTableText: false,
      sortable: false,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "code",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "true",
      controllerId: null,
      show: true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      fieldType: "Link",
      style: {
        color: "#0e4768",
        cursor: "pointer",
        textTransform: "capitalize",
      },
      isAddFormHidden: false,
      isEditFormHidden: false,
    },
    {
      name: "description",
      type: "textarea",
      placeholder: "Description",
      label: "Description",
      width: "130px",
      addFormOrder: 3,
      editFormOrder: 3,
      derivedValue: "description=undefined",
      capitalizeTableText: false,
      sortable: false,
      isClickable: false,
      filter: false,
      actions: [],
      actionsNumber: [],
      id: "description",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "true",
      controllerId: null,
      show: true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      isAddFormHidden: false,
      isEditFormHidden: false,
    },
    // {
    //   name: "abbreviation",
    //   type: "text",
    //   placeholder: "Abbreviation",
    //   label: "Abbreviation",
    //   width: "130px",
    //   addFormOrder: 4,
    //   editFormOrder: 4,
    //   derivedValue: "abbreviation=undefined",
    //   capitalizeTableText: false,
    //   sortable: false,
    //   actions: [],
    //   actionsNumber: [],
    //   id: "abbreviation",
    //   displayinaddForm: "true",
    //   displayineditForm: "true",
    //   displayinlist: "true",
    //   isFieldRequired: "false",
    //   required: true,
    //   displayOptionsInActions: false,
    //   globalSearchField: "true",
    //   controllerId: null,
    //   show: true,
    //   showOrHideFields: [],
    //   mobile: true,
    //   displayInSettings: true,
    //   isAddFormHidden: false,
    //   isEditFormHidden: false,
    // },
    // {
    //   name: "treatmentarea",
    //   type: "text",
    //   placeholder: "Treatment Area",
    //   label: "Treatment Area",
    //   width: "130px",
    //   addFormOrder: 5,
    //   editFormOrder: 5,
    //   derivedValue: "treatmentarea=undefined",
    //   capitalizeTableText: false,
    //   sortable: false,
    //   actions: [],
    //   actionsNumber: [],
    //   id: "treatmentarea",
    //   displayinaddForm: "true",
    //   displayineditForm: "true",
    //   displayinlist: "true",
    //   isFieldRequired: "false",
    //   required: true,
    //   displayOptionsInActions: false,
    //   globalSearchField: "true",
    //   controllerId: null,
    //   show: true,
    //   showOrHideFields: [],
    //   mobile: true,
    //   displayInSettings: true,
    //   isAddFormHidden: false,
    //   isEditFormHidden: false,
    // },
    {
      name: "category",
      type: "dropDown",
      placeholder: "Category",
      label: "Category",
      width: "130px",
      addFormOrder: 5,
      editFormOrder: 5,
      derivedValue: "category=undefined",
      actions: [],
      actionsNumber: [],
      id: "category",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "true",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "true",
      controllerId: null,
      options: categoryOptions,
      fieldType: "dropDown",
      dependent: [],
      show: true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      isAddFormHidden: false,
      isEditFormHidden: false,
    },
    {
      name: "doNotUsuallyBillToInsurance",
      type: "checkbox",
      placeholder: "Do not usually bill to insurance",
      label: "Do not usually bill to insurance",
      derivedValue: "doNotUsuallyBillToInsurance=undefined",
      actions: [],
      width: 110,
      actionsNumber: [],
      id: "category",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      controllerName: null,
      show: true,
      disabled: true,
      mobile: true,
      globalSearchField: "true",
      required: false,
      displayInSettings: true,
      fieldType: "dropDown",
      addFormOrder: 7,
      editFormOrder: 7,
      isAddFormHidden: false,
      isEditFormHidden: false,
    }, {
      name: "fee",
      type: "decimal",
      placeholder: "Fee ",
      label: "Fee",
      width: "130px",
      addFormOrder: 8,
      editFormOrder: 8,
      derivedValue: "fee=undefined",
      capitalizeTableText: false,
      sortable: false,
      actions: [],
      actionsNumber: [],
      id: "fee",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "false",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "true",
      controllerId: null,
      show: true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      isAddFormHidden: false,
      isEditFormHidden: false,
    }, {
      name: "defaultProcedure",
      type: "radio",
      placeholder: "Default Procedure",
      label: "Default Procedure",
      width: "120px",
      addFormOrder: 9,
      editFormOrder: 9,
      derivedValue: "defaultProcedure=undefined",
      capitalizeTableText: false,
      sortable: false,
      actions: [],
      actionsNumber: [],
      id: "defaultProcedure",
      displayinaddForm: "true",
      displayineditForm: "true",
      displayinlist: "true",
      isFieldRequired: "false",
      required: true,
      displayOptionsInActions: false,
      globalSearchField: "false",
      controllerId: null,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ],
      fieldType: "radio",
      show: true,
      showOrHideFields: [],
      mobile: true,
      displayInSettings: true,
      isAddFormHidden: false,
      isEditFormHidden: false,
    }
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hospitalId",
        type: "relateAutoComplete",
        placeholder: "Hospital ID",
        label: "Hospital ID",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Hospital ID",
        derivedValue: "hospitalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1009,
        searchField: "hospitalId",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["hospitalId"],
        controllerName: "Hospitals",
        searchApi: "hospitals",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "hospitalId",
        showOrHideFields: [],
        fieldName: "hospitalId",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Hospitals_hospitalId",
        type: "text",
        placeholder: "Hospitals_hospitalId",
        label: "Hospitals_hospitalId",
        header: "Hospitals hospitalId",
        width: 110,
        parentField: "hospitalId",
        id: "Hospitals_hospitalId",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "hospitalId",
        show: true,
        field: "Hospitals_hospitalId",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "code",
        type: "text",
        placeholder: "Code",
        label: "Code",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Code",
        derivedValue: "code=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "code",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "code",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "abbreviation",
        type: "text",
        placeholder: "Abbreviation",
        label: "Abbreviation",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Abbreviation",
        derivedValue: "abbreviation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "abbreviation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "abbreviation",
        showOrHideFields: [],
        fieldName: "abbreviation",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "treatmentarea",
        type: "text",
        placeholder: "Treatment Area",
        label: "Treatment Area",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Treatment Area",
        derivedValue: "treatmentarea=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "treatmentarea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "treatmentarea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "category",
        type: "dropDown",
        placeholder: "Category",
        label: "Category",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Category",
        derivedValue: "category=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "category",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Nitrous", value: "Nitrous", color: "primary" },
          { label: "Ortho", value: "Ortho", color: "primary" },
          { label: "Reline", value: "Reline", color: "primary" },
          {
            label: "Full Gold Crown",
            value: "Full Gold Crown",
            color: "primary",
          },
          { label: "Xray", value: "Xray", color: "primary" },
          { label: "Denture", value: "Denture", color: "primary" },
          { label: "Prophy", value: "Prophy", color: "primary" },
          { label: "Sealant", value: "Sealant", color: "primary" },
          { label: "Extraction", value: "Extraction", color: "primary" },
          { label: "RCT", value: "RCT", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "category",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "doNotUsuallyBillToInsurance",
        type: "radio",
        placeholder: "Do not usually bill to insurance",
        label: "Do not usually bill to insurance",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Do not usually bill to insurance",
        derivedValue: "doNotUsuallyBillToInsurance=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "doNotUsuallyBillToInsurance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "radio",
        textAlign: "Center",
        show: true,
        field: "doNotUsuallyBillToInsurance",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.procedures}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          PrintRequired={false}
          // globalSearch={'Display Name/Email'}
          // type='Procedures'
          // apiUrl={apiCalls.Procedures}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.procedures}
          globalSearch={"Code/Description"}
          displayName="Procedures"
          type="Procedures"
          routeTo={apiCalls.procedures}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.procedures?.toLowerCase()}
          apiUrl={apiCalls.procedures.toLowerCase()}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="procedures"
          apiUrl={apiCalls.procedures}
        />
      ) : null}
    </span>
  );
};

export default Procedures;
