import React from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import formatDate from '../../UI/FormatDate/formatDate';
import '../../../scss/component/time-picker.scss';


const CustomTimePicker = ({ label, field, value, onChange, fieldState, errors, format = 'h:mm A', use12Hours = true, item, screen, markReq }) => {
  const handleTimeChange = (value) => {
    const formattedTime = value ? value.format('HH:mm A') : '';
    onChange(formattedTime);
  };
  item ? item : {}


  let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
      />
    );
  };

  return (
    <>
      <div>

        <label style={{ fontWeight: "lighter" }} htmlFor={field.name}>

          {label}

          {item?.required && (

            <FontAwesomeIcon

              color="red"

              icon={faAsterisk}

              style={{ width: '7px', marginLeft: "2px" }}

            />

          )}

        </label>

      </div>
      <TimePicker
        showSecond={false}
        value={value ? moment(value, 'HH:mm A') : null}
        onChange={handleTimeChange}
        format={'h:mm A'}
        use12Hours={true}
        inputReadOnly
        minuteStep={15}
      />
      <small className="text-danger ">{fieldState.invalid ? errors[item.name]?.message : ''}</small>
    </>
  );
};

export default CustomTimePicker;